import React from 'react';
import { Link } from 'gatsby';

import Layout from '@rocketseat/gatsby-theme-docs/src/components/Layout';
import SEO from '@rocketseat/gatsby-theme-docs/src/components/SEO';

export default function NotFound() {
  return (
    <Layout title="Страница не найдена!">
      <SEO title="404: Страница не найдена" />
      <p>Вы пытались перейти на страницу которая не существует.</p>
      <p>
        Чтобы вернуться на главную страницу, <Link to="/">нажмите сюда</Link>.
      </p>
    </Layout>
  );
}
